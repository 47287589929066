<template>
  <div class="video-chat" :class="{ open }">
    <font-awesome-icon :icon="['fas', 'cog']" class="settings" />
    <font-awesome-icon :icon="['fas', 'chevron-right']" class="toggle" @click="open = !open" />
    <font-awesome-icon :icon="['fal', 'times-square']" class="close" @click="open = false" />
    <div class="scrollable">
      <div class="user you">
        <div class="fake-video"></div>
        <div class="actions">
          <div class="left">
            <font-awesome-icon :icon="['fas', 'microphone-alt']" />
          </div>
          <div class="right">
            <font-awesome-icon :icon="['fas', 'phone-slash']" />
          </div>
        </div>
        <span class="name">You</span>
      </div>
      <div 
        class="user"
        v-for="i in 8"
        :key="i"
        :class="{ active: i % 2 === 0 }"
      >
        <div class="fake-video"></div>
        <div class="actions">
          <div class="left">
            <font-awesome-icon :icon="['fas', i % 2 === 0 ? 'volume' : 'volume-off']" />
          </div>
          <div class="right">
            <font-awesome-icon :icon="['fas', i % 2 === 0 ? 'comment-plus' : 'comment']" />
          </div>
        </div>
        <span class="name">Person {{ i }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoChat',
  data() {
    return {
      open: false,
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/fonts.scss';

div.video-chat {
  position: absolute;
  bottom: 10px;
  left: 0;
  background-color: rgba(0,0,0,.7);
  box-sizing: border-box;
  height: 175px;
  padding: 0 25px;
  max-width: 100vw;
  z-index: 1;

  @media screen and (max-width: 500px) {
    bottom: 0;
  }

  .settings {
    color: #aaa;
    position: absolute;
    top: 10px;
    left: 12px;
    font-size: 25px;
    cursor: pointer;
  }

  .toggle {
    color: #aaa;
    position: absolute;
    top: 50px;
    left: 17px;
    font-size: 25px;
    cursor: pointer;
  }

  .close {
    color: #aaa;
    position: absolute;
    top: 10px;
    right: 90px;
    font-size: 25px;
    cursor: pointer;
    display: none;

    @media screen and (max-width: 500px) {
      right: 10px;
    }
  }

  div.scrollable {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    align-items: center;

    /* width */
    &::-webkit-scrollbar {
      height: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #aaaaaa;
      border-radius: 3px;
      transition: opacity .2s ease, color .2s ease;
      opacity: 0;
    }

    &:hover::-webkit-scrollbar-thumb {
      opacity: 1;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: white;
    }
  }

  div.user {
    display: none;
    flex-direction: column;
    align-items: center;

    &:not(:last-of-type) {
      margin-right: 10px;
    }

    div.fake-video {
      background-color: black;
      width: 115px;
      height: 115px;
      border: 3px solid #bf00ff;
      box-sizing: border-box;
    }

    div.actions {
      display: flex;
      justify-content: space-between;
      width: 100%;

      div.left,
      div.right {
        width: calc(50% - 3px);
        height: 20px;
        font-size: 16px;
        color: black;
        background-color: #666666;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }

    span.name {
      text-align: center;
      font-family: $be-vietnam;
      color: #bf00ff;
      margin-top: 2px;
      font-weight: bold;
      font-size: 18px;
      line-height: 1;
    }

    &.active {
      div.actions div.left,
      div.actions div.right {
        color: white;
        background-color: #999999;
      }
    }

    &.you {
      div.fake-video {
        border-color: #ff0000;
      }

      div.actions div.left {
        color: white;
        background-color: black;
      }

      div.actions div.right {
        color: white;
        background-color: #aa0000;
      }
    }
  }

  &.open {
    padding: 0 125px 0 50px;
    background: linear-gradient(90deg, 
      rgba(0,0,0,.7) calc(100% - 150px),
      rgba(0,0,0,.6) calc(100% - 100px),
      rgba(0,0,0,0) 100%
    );

    @media screen and (max-width: 500px) {
      padding: 0 50px;
      background: black;
    }

    div.user {
      display: flex;
    }

    .toggle {
      transform: rotate(180deg);
    }

    .close {
      display: block;
    }
  }
}
</style>