<template>
  <div class="home">
    <Scene :data="gallery" v-if="gallery" />
  </div>
</template>

<script>
import Scene from '@/components/gallery/Scene'

import GalleryService from '@/services/gallery.service'
import { mapMutations } from 'vuex'

export default {
  name: 'Home',
  components: {
    Scene,
  },
  data() {
    return {
      gallery: undefined,
    }
  },
  methods: {
    ...mapMutations({
      setGallery: 'gallery/SET_GALLERY'
    }),
    async fetchData() {
      const response = await GalleryService.getData(this.$route.params.slug ?? 'default')
      if (response) {
        this.gallery = response.data
      } else {
        this.$router.push({ path: '/' })
      }
    }
  },
  created() {
    this.fetchData()
  },
}
</script>
