<template>
    <div class="loading" :class="{ loaded: loading.loaded, hidden: loading.hidden }">
      <div class="content">
        <h2>{{ data.title }}</h2>
        <h3>by {{ data.info.artist.title }}</h3>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    loading: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
    }
  },
  mounted() {
     console.log(this.data) 
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/fonts.scss';

.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0,0,0,0.9);
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 1s linear;

  &.loaded {
    opacity: 0;
  }

  &.hidden {
    display: none;
  }

  h2 {
    font-family: $raleway;
    font-size: 4rem;
    color: #ffcc00;
    margin: 0;
  }
  h3 {
    font-family: $caveat;
    font-size: 2rem;
    color: #ffffff;
    margin: 0 20px;
  }
}
</style>
  