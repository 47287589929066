<template>
  <div>
    <VideoChat v-if="data.config.ui.video_menu" />
    <ArtistMenu :data="data" v-if="data.config.ui.artist_menu" />
    <FunctionMenu v-if="data.config.ui.function_menu" />

    <Loading :loading="loading" :data="data" />

    <a-scene id="gallery-scene" load-gallery shadow="type: pcfsoft" inspector-plugin-recast>
      <a-assets id="gallery-assets">
        <img id="bullseye" src="@/assets/icons/nu-dot-circle-light.png" crossorigin="anonymous"/>
        <img id="chevron-right" src="@/assets/icons/nu-chevron-right-regular.png" crossorigin="anonymous"/>
        <img id="chevron-left" src="@/assets/icons/nu-chevron-left-regular.png" crossorigin="anonymous"/>
      </a-assets>
      <a-entity id="gallery-items"></a-entity>

      <a-entity id="gallery-info-panels"></a-entity>

      <camera-rig></camera-rig>
      <!-- Lighting -->
      <a-light type="ambient" position="0 1.5 -12"></a-light>
      
      <a-light id="spotlight-backroom2" type="spot" position="2.33436 2.63715 -17.69558" rotation="-29.999999999999996 -90 0" light="angle: 65; intensity: 0.7; penumbra: 0.2" scale="0.2 0.2 0.2"></a-light>
      <a-light id="spotlight-backroom2" type="spot" position="-2.33436 2.63715 -17.69558" rotation="-29.999999999999996 90 0" light="angle: 65; intensity: 0.7; penumbra: 0.2" scale="0.2 0.2 0.2"></a-light>
    </a-scene>
  </div>
</template>

<script>
import Loading from '@/components/gallery/Loading'
import CameraRig from '@/components/gallery/CameraRig'
import VideoChat from '@/components/menu/VideoChat'
import ArtistMenu from '@/components/menu/Artist'
import FunctionMenu from '@/components/menu/Function'

import Cart from '@/mixins/Cart'
import { Geometry } from '@/helpers/geometry.js'
import { mapGetters } from 'vuex'

const DEFAULT_ITEM_WIDTH_CM = 100

export default {
  name: 'Scene',
  components: {
    CameraRig,
    VideoChat,
    ArtistMenu,
    FunctionMenu,
    Loading
  },
  mixins: [Cart],
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      gallery: undefined,
      duration: {
        position: 2000,
        rotation: 2000,
      },
      timestamp: {
        rotation: null,
        position: null,
      },
      start: {
        rotation: {
          x: null,
          y: null,
        },
        position: {
          x: null,
          z: null,
        },
      },
      end: {
        rotation: {
          x: null,
          y: null,
        },
        position: {
          x: null,
          z: null,
        },
      },
      cameraRigElement: null,
      loading: {
        loaded: false,
        hidden: false,
        promises: []
      }
    }
  },
  methods: {
    init() {
      this.loading.promises.push(new Promise(resolve => {
        this.cameraPosition(this.data.camera)
        this.addModel(this.data.model)
        this.addExpoPanels(this.data.info)
        const items = this.data.items
        Object.keys(items).forEach((key) => {
          this.addAsset(items[key], key)
          this.addItem(items[key], key)
        })

        const self = this
        this.$aframe.registerComponent('look-at-target', {
          init() {
            this.el.addEventListener('click', function () {
              self.lookAtTarget(
                this.getAttribute('position'),
                this.getAttribute('rotation'),
                this.getAttribute('viewing-distance')
              )
            })
          }
        })

        this.$aframe.registerComponent('look-at-target-right', {
          init() {
            this.el.addEventListener('click', function () {
              const currentIdx = Number(this.getAttribute('look-at-target-right'))
              const prevIdx = currentIdx - 1 < 0 ? self.data.items.length - 1 : currentIdx - 1
              
              const prevItem = self.data.items[prevIdx]

              self.lookAtTarget(
                {
                  x: Number(prevItem.position[0]),
                  y: (Number(prevItem.position[1])/2)-0.3,
                  z: Number(prevItem.position[2])
                },
                {
                  x: Number(prevItem.rotation[0]),
                  y: Number(prevItem.rotation[1])-90,
                  z: Number(prevItem.rotation[2])
                },
                prevItem.viewing_distance
              )            
            })
          }
        })

        this.$aframe.registerComponent('look-at-target-left', {
          init() {
            this.el.addEventListener('click', function () {
              const currentIdx = Number(this.getAttribute('look-at-target-left'))
              const nextIdx = (currentIdx + 1) % (self.data.items.length - 1)
              
              const nextItem = self.data.items[nextIdx]

              self.lookAtTarget(
                {
                  x: Number(nextItem.position[0]),
                  y: (Number(nextItem.position[1])/2)-0.3,
                  z: Number(nextItem.position[2])
                },
                {
                  x: Number(nextItem.rotation[0]),
                  y: Number(nextItem.rotation[1])-90,
                  z: Number(nextItem.rotation[2])
                },
                nextItem.viewing_distance
              )  
            })
          }
        })

        this.$aframe.registerComponent('add-to-cart', {
          init() {
            this.el.addEventListener('click', function () {
              let id = this.getAttribute('add-to-cart')
              let item = self.getItemById(id)
              self.addItemToCart(item)
              self.$root.$emit('addToCart', {})
            })
          }
        })

        resolve(true)
      }))
    },
    cameraPosition(camera) {
      this.cameraRigElement.setAttribute('position', `${camera.position[0]} ${camera.position[1]} ${camera.position[2]}`)
      this.cameraRigElement.setAttribute('rotation', `${camera.rotation[0]} ${camera.rotation[1]} ${camera.rotation[2]}`)
    },
    addLookTarget(item, key) {
      let bullseye = document.createElement('a-image')
      bullseye.setAttribute('id', `look-target-${key}`)
      bullseye.setAttribute('color', '#cccccc') 
      bullseye.setAttribute('crossorigin', 'anonymous')
      bullseye.setAttribute('src', '#bullseye')
      bullseye.setAttribute('transparent', true)
      bullseye.setAttribute('side', 'both')
      bullseye.setAttribute('width', 0.2)
      bullseye.setAttribute('height', 0.2)
      bullseye.setAttribute('rotation', `${item.rotation[0]}, ${Number(item.rotation[1])-90}, ${item.rotation[2]}`)
      bullseye.setAttribute('position', `${item.position[0]}, ${(Number(item.position[1])/2)-0.3}, ${item.position[2]}`)
      bullseye.setAttribute('viewing-distance', item.viewing_distance)
      bullseye.setAttribute('class', 'clickable')
      bullseye.setAttribute('look-at-target', '')
      document.getElementById('gallery-items').append(bullseye)

      let chevronRight = document.createElement('a-image')
      chevronRight.setAttribute('id', `look-target-right-${key}`)
      chevronRight.setAttribute('color', '#cccccc') 
      chevronRight.setAttribute('crossorigin', 'anonymous')
      chevronRight.setAttribute('src', '#chevron-right')
      chevronRight.setAttribute('transparent', true)
      chevronRight.setAttribute('side', 'both')
      chevronRight.setAttribute('width', 0.2)
      chevronRight.setAttribute('height', 0.2)
      chevronRight.setAttribute('rotation', `${item.rotation[0]}, ${Number(item.rotation[1])-90}, ${item.rotation[2]}`)
      const positionTwoRight = Number(item.rotation[1])-90 > 0 ? Number(item.position[2])-0.3 : Number(item.position[2])+0.3
      chevronRight.setAttribute('position', `${item.position[0]}, ${(Number(item.position[1])/2)-0.3}, ${positionTwoRight}`)
      bullseye.setAttribute('viewing-distance', item.viewing_distance)
      chevronRight.setAttribute('class', 'clickable')
      chevronRight.setAttribute('look-at-target-right', `${key}`)
      document.getElementById('gallery-items').append(chevronRight)
      
      
      let chevronLeft = document.createElement('a-image')
      chevronLeft.setAttribute('id', `look-target-left-${key}`)
      chevronLeft.setAttribute('color', '#cccccc') 
      chevronLeft.setAttribute('crossorigin', 'anonymous')
      chevronLeft.setAttribute('src', '#chevron-left')
      chevronLeft.setAttribute('transparent', true)
      chevronLeft.setAttribute('side', 'both')
      chevronLeft.setAttribute('width', 0.2)
      chevronLeft.setAttribute('height', 0.2)
      chevronLeft.setAttribute('rotation', `${item.rotation[0]}, ${Number(item.rotation[1])-90}, ${item.rotation[2]}`)
      const positionTwoLeft = Number(item.rotation[1])-90 > 0 ? Number(item.position[2])+0.3 : Number(item.position[2])-0.3
      chevronLeft.setAttribute('position', `${item.position[0]}, ${(Number(item.position[1])/2)-0.3}, ${positionTwoLeft}`)
      bullseye.setAttribute('viewing-distance', item.viewing_distance)
      chevronLeft.setAttribute('class', 'clickable')
      chevronLeft.setAttribute('look-at-target-left', `${key}`)
      document.getElementById('gallery-items').append(chevronLeft)
    },
    addModel(model) {
      this.loading.promises.push(new Promise(resolve => {
        let modelAsset = document.createElement('a-asset-item')
        modelAsset.setAttribute('crossorigin', 'anonymous')
        modelAsset.setAttribute('id', 'gallery-model')
        modelAsset.setAttribute('src', model.src)
        document.getElementById('gallery-assets').appendChild(modelAsset)

        modelAsset.addEventListener('loaded', () => {
          resolve(true)
        })
      }))

      this.loading.promises.push(new Promise(resolve => {
        let modelEntity = document.createElement('a-entity')
        modelEntity.setAttribute('gltf-model', '#gallery-model')
        modelEntity.setAttribute('position', model.position)
        modelEntity.setAttribute('rotation', model.rotation)
        modelEntity.setAttribute('scale', model.scale)
        document.getElementById('gallery-scene').appendChild(modelEntity)

        modelEntity.addEventListener('loaded', () => {
          resolve(true)
        })
      }))

      if (model.navmesh) {
        this.addNavMesh(model)
      }
    },
    addAsset(item, key) {
      let type = item.type == 'video' ? 'video' : 'img'
      let asset = document.createElement(type)
      if (type == 'video') {
        asset.setAttribute('autoplay', true)
        asset.setAttribute('loop', true)
      }
      asset.crossOrigin = 'anonymous'
      asset.setAttribute('id', `gallery-${key}`)
      asset.setAttribute('src', `${item.src}`)
      document.getElementById('gallery-assets').appendChild(asset)
    },
    addItem(item, key) {
      let itemEntity = document.createElement('a-box')
      itemEntity.crossOrigin = 'anonymous'
      itemEntity.setAttribute('id', `item-${key}`)
      if (item.type == 'gif') {
        itemEntity.setAttribute('material', `shader:gif;src: #gallery-${key}`)
      } else {
        itemEntity.setAttribute('material', `src: #gallery-${key}`)
      }
      itemEntity.setAttribute('rotation', `${item.rotation[0]}, ${item.rotation[1]}, ${item.rotation[2]}`)
      itemEntity.setAttribute('position', `${item.position[0]}, ${item.position[1]}, ${item.position[2]}`)
      itemEntity.setAttribute('class', 'clickable')
      itemEntity.setAttribute('cursor-listener', '')
      itemEntity.setAttribute('transparent', 'true')
      if (this.data.config.ui.cart) {
        this.addCartButton(item, key)
      }
      document.getElementById('gallery-items').appendChild(itemEntity)
      this.setImageSize(item, key)
      if (this.data.config.ui.look_target) {
        this.addLookTarget(item, key)
      }
    },
    addCartButton(item, key) {
      let cartButton = document.createElement('a-circle')
      cartButton.setAttribute('id', `cart-button-${key}`)
      cartButton.setAttribute('color', '#ff0000') 
      cartButton.setAttribute('crossorigin', 'anonymous')
      cartButton.setAttribute('transparent', true)
      cartButton.setAttribute('side', 'both')
      cartButton.setAttribute('radius', 0.04)
      cartButton.setAttribute('rotation', `${item.rotation[0]}, ${Number(item.rotation[1])-90}, ${item.rotation[2]}`)
      cartButton.setAttribute('position', `${item.position[0]}, ${(Number(item.position[1])/2)}, ${item.position[2]}`)
      cartButton.setAttribute('class', 'clickable')
      cartButton.setAttribute('add-to-cart', item.id)
      document.getElementById('gallery-items').append(cartButton)
    },
    addNavMesh(model) {
      this.loading.promises.push(new Promise(resolve => {
        let modelAsset = document.createElement('a-asset-item')
        modelAsset.setAttribute('crossorigin', 'anonymous')
        modelAsset.setAttribute('id', 'navmesh')
        modelAsset.setAttribute('src', model.navmesh)
        document.getElementById('gallery-assets').appendChild(modelAsset)

        modelAsset.addEventListener('loaded', () => {
          resolve(true)
        })
      }))
      
      
      this.loading.promises.push(new Promise(resolve => {
        let modelEntity = document.createElement('a-entity')
        modelEntity.setAttribute('id', 'gallery-navmesh')
        modelEntity.setAttribute('gltf-model', '#navmesh')
        modelEntity.setAttribute('new-nav-mesh', '')
        modelEntity.setAttribute('visible', 'false')
        document.getElementById('gallery-scene').appendChild(modelEntity)

        modelEntity.addEventListener('loaded', () => {
          resolve(true)
        })
      }))
    },
    setImageSize(item, key) {
      let self = this
      var img = new Image()
      img.onload = function () {
        let itemAsset = document.getElementById(`item-${key}`)
        let width = item.width ? item.width / 100 : DEFAULT_ITEM_WIDTH_CM / 100
        let aspect = img.height / img.width
        let height = width * aspect
        itemAsset.setAttribute('width', item.depth ? item.depth / 100 : 0.05)
        itemAsset.setAttribute('depth', width)
        itemAsset.setAttribute('height', height)
        item.height = height
        self.addInfoPanel(item, key, width, width * aspect)
      }
      img.src = item.src
    },
    addExpoPanels(info) {
      let artistPanel = document.createElement('a-plane')
      let artist = info.artist
      artistPanel.setAttribute('position', `${artist.position[0]} ${artist.position[1]} ${artist.position[2]}`)
      artistPanel.setAttribute('rotation', `${artist.rotation[0]} ${artist.rotation[1]} ${artist.rotation[2]}`)
      artistPanel.setAttribute('material', 'vertexColors: face; side: back')
      if (artist.profile_pic) {
        let profilePicture = document.createElement('a-plane')
        profilePicture.setAttribute('material', `src: ${artist.profile_pic}`)
        profilePicture.setAttribute('position', '-0.2 0.15 0')
        profilePicture.setAttribute('width', 0.6)
        profilePicture.setAttribute('height', 0.6)
        profilePicture.setAttribute('transparent', 'true')
        artistPanel.appendChild(profilePicture)
      }
      let panelTitle = document.createElement('a-text')
      panelTitle.setAttribute('id', 'artistbio')
      panelTitle.setAttribute('position', '-0.5 -0.35 0')
      panelTitle.setAttribute('value', `${artist.title}`)
      panelTitle.setAttribute('text', 'shader: msdf; anchor: left; width: 2; font: https://cdn.aframe.io/examples/ui/Viga-Regular.json; color: #333333;')
      artistPanel.appendChild(panelTitle)

      let panelText = document.createElement('a-entity')
      panelText.setAttribute('position', '-0.5 -0.5 0')
      panelText.setAttribute('text', ` width: 1.2; baseline: top; lineHeight: 70; shader: msdf; anchor: left; font: https://cdn.aframe.io/examples/ui/Viga-Regular.json; color: #333333; value: ${artist.description}`)
      artistPanel.appendChild(panelText)

      document.getElementById('gallery-info-panels').appendChild(artistPanel)

      let exhibition = info.exhibition
      this.loading.promises.push(new Promise(resolve => {
        const expoPanelTitleFont = exhibition['title-font'] || require('@/assets/fonts/Roboto-Bold.ttf')
        const expoPanelDescriptionFont = exhibition['title-font'] || require('@/assets/fonts/Roboto-Light.ttf')
        const expoPanelColor = exhibition['title-color'] || '#333333'
        const expoPanelOpacity = exhibition['title-opacity'] / 100 || 1
        const expoPanelFontSize = exhibition['title-font-size'] || 0.06

        let expoPanel = document.createElement('a-plane')
        expoPanel.setAttribute('position', `${exhibition.position[0]} ${exhibition.position[1]} ${exhibition.position[2]}`)
        expoPanel.setAttribute('rotation', `${exhibition.rotation[0]} ${exhibition.rotation[1]} ${exhibition.rotation[2]}`)
        expoPanel.setAttribute('material', 'vertexColors: face; side: back')
  
        let expoPanelTitle = document.createElement('a-entity')
        expoPanelTitle.setAttribute('position', '0 0 0')
        expoPanelTitle.setAttribute('troika-text', `font-size: ${expoPanelFontSize}; max-width: 1.2; baseline:top; fill-opacity: ${expoPanelOpacity}; align: 'right'; anchor: 'left'; color: ${expoPanelColor}; value: ${exhibition.title}; font: ${expoPanelTitleFont};`)

        let expoPanelText = document.createElement('a-entity')
        expoPanelText.setAttribute('position', '0 -0.2 0')
        expoPanelText.setAttribute('troika-text', `font-size: ${expoPanelFontSize}; lineHeight: 1.6; max-width: 1.2; baseline:top; fill-opacity: ${expoPanelOpacity}; align: 'right'; anchor: 'left'; color: ${expoPanelColor}; value: ${exhibition.description}; font: ${expoPanelDescriptionFont};`)
  
        expoPanel.appendChild(expoPanelTitle)
        expoPanel.appendChild(expoPanelText)
  
        document.getElementById('gallery-info-panels').appendChild(expoPanel)

        expoPanel.addEventListener('loaded', () => {
          resolve(true)
        })
      }))
    },
    addInfoPanel(picture, key) {
      let panelRotation = picture.rotation[1]
      let panelWidth = 1.2
      let panelHeight= 0.6
      let positionX = picture.position[0]
      let positionY = picture.position[1]
      let positionZ = picture.position[2]
      
      let halfWidth = (parseFloat(picture.width)/100)/2

      switch (picture.rotation[1] * 1) {
        case 270:
          panelRotation = 0
          positionX = (picture.position[0] * 1) + 1.4
          positionY = (picture.position[1] * 1) + 0.4
          positionZ = (picture.position[2] * 1)
          break
        case 180:
          panelRotation = picture.rotation[1] - 90
          positionX = parseFloat(picture.position[0])
          positionY = parseFloat(picture.position[1])
          positionZ = parseFloat(picture.position[2]) - halfWidth - 0.6 //panel width/2
          break
        case 90:
          panelRotation = 180
          positionX = (picture.position[0] * 1) - 1.4
          positionY = (picture.position[1] * 1) + 0.4
          positionZ = (picture.position[2] * 1)
          break
        case 0:
          panelRotation = 270
          positionX = parseFloat(picture.position[0])
          positionY = parseFloat(picture.position[1])
          positionZ = parseFloat(picture.position[2]) + halfWidth + 0.6 //panel width/2
          break
      }

      let newInfoPanel = document.createElement('a-entity')
      newInfoPanel.crossOrigin = 'anonymous'
      newInfoPanel.setAttribute('id', `info-panel-${key}`)
      newInfoPanel.setAttribute('visible', true)
      newInfoPanel.setAttribute('info-panel', '')
      newInfoPanel.setAttribute('rotation', `${picture.rotation[0]}, ${panelRotation}, ${picture.rotation[2]}`)
      newInfoPanel.setAttribute('position', `${positionX}, ${positionY}, ${positionZ}`)
      newInfoPanel.setAttribute('geometry', `primitive: plane; width: ${panelWidth}; height: ${panelHeight}`)
      newInfoPanel.setAttribute('material', 'color: #333333; shader: flat; transparent: true')
      newInfoPanel.setAttribute('material', 'vertexColors: face; side: back')
      newInfoPanel.setAttribute('class', 'clickable info-panel')
      //newInfoPanel.setAttribute('look-at', '[camera]');

      const titleFont = picture['title-font'] || require('@/assets/fonts/Roboto-Bold.ttf')
      const titleColor = picture['title-color'] || '#333333'
      const titleOpacity = picture['title-opacity'] / 100 || 1
      const titleFontSize = picture['title-font-size'] || 0.06
      const descriptionFont = picture['description-font'] || require('@/assets/fonts/Roboto-Regular.ttf')
      const descriptionColor = picture['description-color'] || '#333333'
      const descriptionOpacity = picture['description-opacity'] / 100 || 1
      const descriptionFontSize = picture['description-font-size'] || 0.04

      let anchor = 'left'

      if (picture['text-position'] && picture['text-position'].split('-')[1] === 'left') {
        anchor = 'right'
      }

      let titlePosition = '-0.5 0.3 0'
      let descriptionPosition = '-0.5 0.20 0'

      if (picture['text-position'] === 'middle-right') {
        titlePosition = '-0.5 -0.3 0'
        descriptionPosition = '-0.5 -0.4 0'
      } else if (picture['text-position'] === 'bottom-right') {
        titlePosition = '-0.5 -0.9 0'
        descriptionPosition = '-0.5 -1 0'
      } else if (picture['text-position'] === 'top-left') {
        titlePosition = '-2.3 0.3 0'
        descriptionPosition = '-2.3 0.20 0'
      } else if (picture['text-position'] === 'middle-left') {
        titlePosition = '1.2 -0.3 0'
        descriptionPosition = '1.2 -0.4 0'
      } else if (picture['text-position'] === 'bottom-left') {
        titlePosition = '1.2 -0.9 0'
        descriptionPosition = '1.2 -1 0'
      }

      let panelTitle = document.createElement('a-entity')
      panelTitle.setAttribute('id', `info-panel-title-${key}`)
      panelTitle.setAttribute('position', titlePosition)
      panelTitle.setAttribute('troika-text', `font-size: ${titleFontSize}; max-width: 1; fill-opacity: ${titleOpacity}; align: ${anchor}; anchor: ${anchor}; color: ${titleColor}; value: ${picture.title}; font: ${titleFont};`)

      let panelText = document.createElement('a-entity')
      panelText.setAttribute('id', `info-panel-description-${key}`)
      panelText.setAttribute('position', descriptionPosition)
      panelText.setAttribute('troika-text', `font-size: ${descriptionFontSize}; max-width: 0.7; fill-opacity: ${descriptionOpacity}; align: ${anchor}; baseline: top; anchor: ${anchor}; color: ${descriptionColor}; value: ${picture.description}; font: ${descriptionFont};`)

      newInfoPanel.appendChild(panelTitle)
      newInfoPanel.appendChild(panelText)

      document.getElementById('gallery-info-panels').appendChild(newInfoPanel)
    },
    loadNavMesh(self) {
      this.loading.promises.push(new Promise(resolve => {
        const object = self.el.getObject3D('mesh')
        const scene = self.el.sceneEl.object3D
  
        if (!object) {
          resolve(true)
          return
        }
  
        let navMesh
        object.traverse((node) => {
          if (node.isMesh &&
            (!self.nodeName || node.name === self.nodeName)) navMesh = node
        })
  
        if (!navMesh) {
          resolve(true)
          return
        }
  
        const navMeshGeometry = navMesh.geometry.isBufferGeometry
          ? new Geometry().fromBufferGeometry(navMesh.geometry)
          : navMesh.geometry.clone()
  
        scene.updateMatrixWorld()
        navMeshGeometry.applyMatrix(navMesh.matrixWorld)
        self.system.setNavMeshGeometry(navMeshGeometry)
  
        self.hasLoadedNavMesh = true
        resolve(true)
      }))
    },
    lookAtTarget(position, rotation, viewingDistance) {
      let distanceFromPic = viewingDistance / 100
      let viewerRotation = 90
      switch (rotation.y) {
          case -90:
              distanceFromPic *= -1
              viewerRotation = -90
          break
      }
      const camera = document.getElementById('camera')
      const controls = camera.components['look-controls']
      const cameraRotation = {
        x: controls.pitchObject.rotation.x,
        y: controls.yawObject.rotation.y * (180/Math.PI)
      }


      const cameraRigPosition = this.cameraRigElement.getAttribute('position')
      const cameraRigRotation = this.cameraRigElement.getAttribute('rotation')

      this.timestamp.position = null
      this.start.position.x = cameraRigPosition.x % 360
      this.start.position.z = cameraRigPosition.z % 360
      this.end.position.x = (position.x + distanceFromPic - cameraRigPosition.x) % 360
      this.end.position.z = (position.z - cameraRigPosition.z) % 360

      this.timestamp.rotation = null
      this.start.rotation.y = cameraRigRotation.y % 360
      this.start.rotation.x = cameraRotation.x % 360
      let end = {
        x: cameraRotation.x * -1,
        y: (viewerRotation - cameraRigRotation.y - cameraRotation.y) % 360
      }
      if (end.y > 180) {
        end.y -= 360
      } else if (end.y < -180) {
        end.y += 360
      }
      this.end.rotation.x = end.x
      this.end.rotation.y = end.y

      requestAnimationFrame(this.animationPositionStep)
      requestAnimationFrame(this.animationRotationStep)
    },
    animationPositionStep(timestamp) {
      if (this.timestamp.position === null) this.timestamp.position = timestamp
      const progress = timestamp - this.timestamp.position
      const x = this.start.position.x + this.end.position.x * progress / this.duration.position
      const z = this.start.position.z + this.end.position.z * progress / this.duration.position
      this.cameraRigElement.setAttribute('position', `${x} 0 ${z}`)
      if (progress < this.duration.position) {
        requestAnimationFrame(this.animationPositionStep)
      }
    },
    animationRotationStep(timestamp) {
      if (this.timestamp.rotation === null) this.timestamp.rotation = timestamp
      const progress = timestamp - this.timestamp.rotation
      const rotation = {
        x: this.start.rotation.x + this.end.rotation.x * progress / this.duration.rotation,
        y: this.start.rotation.y + this.end.rotation.y * progress / this.duration.rotation
      }
      const camera = document.getElementById('camera')
      const controls = camera.components['look-controls']
      controls.pitchObject.rotation.x = rotation.x
      this.cameraRigElement.setAttribute('rotation', `0 ${rotation.y} 0`)
      if (progress < this.duration.rotation) {
        requestAnimationFrame(this.animationRotationStep)
      }
    },
  },
  mounted() {
    const self = this
    this.cameraRigElement = document.getElementById('cameraRig')
    
    this.$aframe.registerComponent('load-gallery', {
      init() {
        self.init()
      }
    })
    
    this.$aframe.registerComponent('new-nav-mesh', {
      schema: {
        nodeName: { type: 'string' }
      },

      init() {
        this.system = this.el.sceneEl.systems.nav
        this.hasLoadedNavMesh = false
        this.nodeName = this.data.nodeName
        this.el.addEventListener('object3dset', () => self.loadNavMesh(this))
      },

      play() {
        if (!this.hasLoadedNavMesh) self.loadNavMesh(this)
      },
    })

    this.$root.$on('lookAtTarget', data => {
      const position = {
        x: Number(data.position[0]),
        y: (Number(data.position[1])/2)-0.3,
        z: Number(data.position[2]),
      }
      const rotation = {
        x: Number(data.rotation[0]),
        y: Number(data.rotation[1]-90),
        z: Number(data.rotation[2]),
      }
      self.lookAtTarget(position, rotation, data.viewing_distance || 180)
    })
    


    setTimeout(() => {
      Promise.all(this.loading.promises).then(() => {
        this.loading.loaded = true
        setTimeout(() => {
          this.loading.hidden = true
        }, 1000)
      })
    }, 5000)
  },
  computed: {
    ...mapGetters({
      getItemById: 'gallery/getItemById'
    })
  },
  beforeDestroy() {
    this.$root.$off('lookAtTarget')
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/fonts.scss';

.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0,0,0,0.9);
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 1s linear;

  &.loaded {
    opacity: 0;
  }

  &.hidden {
    display: none;
  }

  h2 {
    font-family: $raleway;
    font-size: 4rem;
    color: #ffcc00;
    margin: 0;
  }
  h3 {
    font-family: $caveat;
    font-size: 2rem;
    color: #ffffff;
    margin: 0 20px;
  }
}
</style>
  