export default {
  namespaced: true,
  state: {
    carts: []
  },
  getters: {
    cartBySlug: state => slug => {
      const cart = state.carts.find(el => el.slug === slug)
      return cart?.items || []
    }
  },
  mutations: {
    addItemToCartBySlug(state, payload) {
      let cart = state.carts.find(el => el.slug === payload.slug)

      if (cart) {
        cart.items.push(payload.item)
      } else {
        state.carts.push({
          slug: payload.slug,
          items: [payload.item],
        })
      }
    },
    removeItemFromCartBySlug(state, payload) {
      let cart = state.carts.find(el => el.slug === payload.slug)
      console.log(cart, cart.items[payload.index], payload)
      if (cart && cart.items[payload.index]) {
        cart.items.splice(payload.index, 1)
      }
    }
  },
}
