import axios from 'axios'

const GalleryApi = axios.create()

const ApiService = {
  initGalleryApi(baseURL) {
    GalleryApi.defaults.baseURL = baseURL
  },
  customRequest(data) {
    return axios(data)
  },
}



export { ApiService, GalleryApi }
