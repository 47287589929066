import { GalleryApi } from './api.service'

const GalleryService = {
  async getData(slug) {
    const requestData = {
      method: 'get',
      url: `/gallery/data/${slug}`,
    }

    try {
      let response = await GalleryApi(requestData)
      return response.data
    } catch(error) {
      return false
    }
  },
}

export default GalleryService