<template>
  <div class="artist" :class="{ open }">
    <div class="row">
      <div class="menu">
        <font-awesome-icon :icon="['fas', 'palette']" class="icons palette" @click="openMenu" />
        <font-awesome-icon :icon="['fas', 'images']" class="icons gallery" />
        <font-awesome-icon :icon="['fas', 'user']" class="icons user" />
        <font-awesome-icon :icon="['fas', 'comment-alt-lines']" class="icons comments" />
      </div>
      <div class="info-container" v-if="open">
        <div class="fixed">
          <font-awesome-icon :icon="['fas', 'cog']" class="icons" />
          <font-awesome-icon :icon="['fal', 'times-square']" class="icons" @click="open = false"/>
        </div>
        <p class="artist">
          <span class="bold">ARTIST </span>
          <span class="thin">{{ data.info.artist.title }}</span>
        </p>
        <p class="gallery">
          <span class="bold">Gallery Title </span>
          <span class="thin">{{ data.info.exhibition.title }}</span>
        </p>
        <p>
          <span class="thin">
            {{ data.info.exhibition.description }}
          </span>
        </p>
      </div>
    </div>
    <div class="title-section">
      <div class="icon">
        <font-awesome-icon :icon="['fas', 'walking']" />
      </div>
      <div class="title">
        <span class="white">ARTWORK</span>
        <div class="separator">
          <span class="white">&#60;</span>
          <span class="white">|</span>
          <span>&#62;</span>
        </div>
        <span>EVENTS</span>
      </div>
      <div class="icon">
        <font-awesome-icon :icon="['far', 'calendar-star']" />
      </div>
    </div>
    <div class="scrollable">
      <div
        class="more hoverable"
        v-for="(item, idx) in data.items" :key="idx"
        @click="lookAtTarget(item.position, item.rotation)"
      >
        <div class="icon">
          <font-awesome-icon :icon="['fas', 'bullseye']" />
        </div>
        <p>{{ item.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArtistMenu',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      open: false,
    }
  },
  methods: {
    openMenu() {
      if (window.innerWidth < 780) {
        this.$root.$emit('closeFunctionMenu')
      }
      this.open = true
    },
    lookAtTarget(position, rotation) {
      this.$root.$emit('lookAtTarget', { position, rotation })
      this.open = false
    }
  },
  mounted() {
    this.$root.$on('closeArtistMenu', () => { this.open = false })
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/fonts.scss';

div.artist {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.7);
  display: flex;
  flex-direction: column;
  max-width: 386px;
  max-height: 70vh;
  z-index: 1;
  box-sizing: border-box;

  div.row {
    display: flex;
  
    div.menu {
      width: 50px;
      padding: 13px 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;

      .icons {
        font-size: 25px;
        color: #aaaaaa;
        cursor: pointer;

        &:not(:last-of-type) {
          margin-bottom: 15px;
        }
        
        &:hover {
          color: white;
        }
      }

      .palette {
        font-size: 35px;
        margin-bottom: 40px;
        color: white;
      }
    }

    div.info-container {
      width: calc(100% - 50px);
      position: relative;
      padding: 20px 30px 20px 10px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;

      div.fixed {
        position: absolute;
        top: 20px;
        right: 20px;
        display: flex;
        font-size: 25px;

        .icons {
          color: #aaaaaa;
          cursor: pointer;

          &:not(:last-of-type) {
            margin-right: 10px;
          }

          &:hover {
            color: white;
          }
        }
      }

      p {
        color: white;
        font-family: $be-vietnam;
        font-size: 14px;

        &:not(:last-of-type) {
          margin-bottom: 20px;
        }

        .bold {
          font-weight: 700;
        }
        
        .thin {
          font-weight: 100;
        }
      }
    }
  }

  &.open {
    width: 100%;
    padding-bottom: 20px;

    @media screen and (max-width: 500px) {
      z-index: 2;
      background-color: black;
      max-width: unset;
      max-height: 100vh;
      height: 100vh;
    }

    div.row,
    div.more,
    div.title-section {
      width: 100%;
    }

    div.more,
    div.title-section {
      display: flex;
    }
  }

  div.scrollable {
    width: calc(100% - 3px);
    max-height: 100%;
    overflow: auto;

    /* width */
    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #aaaaaa;
      border-radius: 3px;
      transition: opacity .2s ease, color .2s ease;
      opacity: 0;
    }

    &:hover::-webkit-scrollbar-thumb {
      opacity: 1;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: white;
    }
  }

  div.more {
    display: none;
    align-items: center;
    margin: 3px 0;
    cursor: pointer;

    div.icon {
      width: 50px;
      padding: 0 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #aaaaaa;
      box-sizing: border-box;
    }

    p {
      max-width: calc(100% - 50px);
      padding: 0 30px 0 10px;
      font-size: 14px;
      font-family: $be-vietnam;
      color: white;
    }

    &.hoverable:hover div.icon {
      color: white;
    }
  }

  div.title-section {
    display: none;
    align-items: center;
    margin-bottom: 15px;

    div.icon {
      width: 50px;
      padding: 0 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      box-sizing: border-box;
      font-size: 35px;

      &:last-of-type {
        width: 65px;
        color: #aaaaaa;
        padding-right: 18px
      }
    }

    div.title {
      width: calc(100% - 115px);
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: $be-vietnam;
      font-weight: 700;
      color: #aaaaaa;

      .white {
        color: white;
      }

      div.separator {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 45px;
      }
    }
  }
}
</style>