import { mapMutations } from 'vuex'

// replace all hardcoded slug by dynamic slug when the PR slug will be merged
export default {
  computed: {
    currentCart() {
      return this.$store.getters['cart/cartBySlug']('a-place-for-the-mind-to-rest')
    },
    lineItems() {
      return this.cartBySlug('a-place-for-the-mind-to-rest')
    },
    cartTotal() {
      let items = this.cartBySlug('a-place-for-the-mind-to-rest')
      let total = 0
      items.forEach(item => {
        total += parseFloat(item.price)
      })
      return total.toFixed(2)
    }
  },
  methods: {
    ...mapMutations('cart', ['addItemToCartBySlug', 'removeItemFromCartBySlug']),
    addItemToCart(item) {
      this.addItemToCartBySlug({
        slug: 'a-place-for-the-mind-to-rest',
        item
      })
    },
    removeItemFromCart(index) {
      this.removeItemFromCartBySlug({
        slug: 'a-place-for-the-mind-to-rest',
        index
      })
    }
  }
}