import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import { domain, clientId } from '../auth_config.json'
import { Auth0Plugin } from './auth'

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})

import './plugins/fontawesome'
const aframe = require('aframe')
require('aframe-extras')
require('aframe-gif-component')
require('aframe-htmlembed-component')
require('aframe-teleport-controls')
require('aframe-thumb-controls-component')
require('aframe-troika-text')

import { ApiService } from './services/api.service'



Vue.config.ignoredElements = [
  'a-scene',
  'a-entity',
  'a-camera',
  'a-box',
  'a-assets',
  'a-asset-item',
  'a-sky',
  'a-light'
]
Vue.prototype.$aframe = aframe


ApiService.initGalleryApi(process.env.VUE_APP_GALLERY_API)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
