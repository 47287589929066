import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    params: { 
      slug: 'default',
    },
    component: Home
  },
  {
    path: '/gallery/:slug',
    name: 'Gallery',
    component: Home
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.PUBLIC_PATH + '/',
  routes
})

export default router
