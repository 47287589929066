import _ from 'lodash'
export default {
    namespaced: true,
    state: {
      gallery: null
    },
    actions: {

    },
    getters: {
      getItems: state => {
        return state.gallery.items
      },
      getItemById: state => id => {
        const item = _.find(state.gallery.items, {id: parseInt(id) }) || {}
        return item
      },
    },
    mutations: {
      SET_GALLERY (state, payload) {
        state.gallery = payload
      }
    },
  }
  