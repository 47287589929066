import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import gallery from './gallery/gallery'
import cart from './cart/cart'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    cart,
    gallery
  },
  plugins: [
    createPersistedState({
      paths: ['cart']
    })
  ],
})
