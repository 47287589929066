<template>
  <div class="function" :class="{ open }">
    <img :src="logoImage" alt="Binary Galleries Logo" class="logo">
    <template v-if="!open">
      <font-awesome-icon @click="openMenu" class="closed-icons" :icon="['fas', 'user']" />
      <font-awesome-icon class="closed-icons" :icon="['fal', 'shopping-cart']" />
      <font-awesome-icon class="closed-icons" :icon="['fas', 'heart']" />
      <font-awesome-icon class="closed-icons" :icon="['fal', 'user-headset']" />
    </template>
    <template v-else>
      <div class="fixed">
        <font-awesome-icon :icon="['fal', 'times-square']" class="icons" @click="open = false"/>
        <font-awesome-icon :icon="['fas', 'cog']" class="icons" />
      </div>
      <div class="grid">
        <div class="elem">
          <font-awesome-icon :icon="['fas', 'user']" />
        </div>
        <div class="elem">
          <font-awesome-icon :icon="['fas', 'palette']" />
        </div>
        <div class="elem">
          <font-awesome-icon :icon="['fas', 'images']" />
        </div>
        <div class="elem">
          <font-awesome-icon :icon="['fal', 'shopping-cart']" />
        </div>
        <div class="elem">
          <font-awesome-icon :icon="['fal', 'fingerprint']" />
        </div>
        <div class="elem active">
          <font-awesome-icon :icon="['fab', 'ethereum']" />
        </div>
        <div class="elem">
          <font-awesome-icon :icon="['fal', 'credit-card']" />
        </div>
        <div
          class="elem"
          v-if="!$auth.loading && $auth.isAuthenticated"
        >
          <font-awesome-icon :icon="['fal', 'sign-out-alt']" @click="logout" />
        </div>
      </div>
      <div class="scrollable">
          <div class="content">
            <ul class="list">
                <li class="list-item" v-for="(item, index) in lineItems" :key="item.id">
                    <div class="list-item__image">
                        <img :src="item.src" alt="Thumbnail" @click="lookAtTarget(item.position, item.rotation)">
                    </div>
                    <div class="list-item__content">
                        <h4>{{ item.title }}</h4>
                        <h5>{{ item.currency.symbol }}{{ parseFloat(item.price).toFixed(2) }}</h5>
                    </div>
                    <a href="#" v-bind:class="removeItemFromCart" @click="removeItemFromCart(index)">X</a>
                </li>
            </ul>
          </div>
        <div class="info">
          <div class="side">
            <font-awesome-icon class="icon" :icon="['fab', 'ethereum']" />
          </div>
        </div>
        <div class="separator"></div>

        <div class="total">
          <div class="content">
            <span class="recap">
              <span class="thin">{{currentCart.length}}</span> items = {{ cartTotal }}
            </span>
            <div class="grid">
              <span></span>
              <span></span>
              <div class="elem no-hover">
                <font-awesome-icon :icon="['fas', 'images']" />
              </div>
              <div class="elem no-hover">
                <font-awesome-icon :icon="['fal', 'fingerprint']" />
              </div>
            </div>
          </div>
          <div class="side">
            <font-awesome-icon :icon="['fal', 'shopping-cart']" />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Cart from '@/mixins/Cart'
import { mapGetters } from 'vuex'
//import { StripeElements, StripeElement } from 'vue-stripe-elements-plus'

export default {
  name: 'FunctionMenu',
  mixins: [Cart],
  components: {
    //StripeElements,
    //StripeElement
  },
  data() {
    this.publishableKey = process.env.STRIPE_PUBLISHABLE_KEY
    return {
      open: false,
      loading: false,
      stripeKey: 'pk_test_51INhwOJ3tdgbjn2gPOUiV3P3sRIwzx23fiJlswDX8U1v4lvoweiAACX94xKkhNpsV9Zu1NkMkfLLpeuUXaCv8r8800DIS8tFjz',
      stripe: '',
      elements: '',
      card: '',
      instanceOptions: {
        // https://stripe.com/docs/js/initializing#init_stripe_js-options
      },
      elementsOptions: {
        // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
      },
      cardOptions: {
        // reactive
        // remember about Vue 2 reactivity limitations when dealing with options
        value: {
          postalCode: ''
        }
        // https://stripe.com/docs/stripe.js#element-options
      }
    }
  },
  methods: {
    openMenu() {
      if (window.innerWidth < 780) {
        this.$root.$emit('closeArtistMenu')
      }
      this.open = true
    },
    login() {
      this.$auth.loginWithRedirect()
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.href
      })
    },
    lookAtTarget(position, rotation) {
      this.$root.$emit('lookAtTarget', { position, rotation })
      this.open = false
    }
  },
  computed: {
    logoImage() {
      return this.open ? 
        require('@/assets/images/logo_dark.png')
        : require('@/assets/images/logo_white.png')
    },
    ...mapGetters({
      cartBySlug: 'cart/cartBySlug'
    })
  },
  mounted() {
    this.$root.$on('closeFunctionMenu', () => { this.open = false })
    this.$root.$on('addToCart', () => {
      this.open = true
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/fonts.scss';

div.function {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  background-color: rgba(255,255,255,.9);
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  z-index: 1;

  img.logo {
    width: 200px;
    position: absolute;
    top: 30px;
    right: 70px;
    z-index: 1;
  }

  .closed-icons {
    font-size: 30px;
    
    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }

  &.open {
    width: 386px;
    max-height: 70vh;
    padding: 115px 0px 20px 20px;
    box-sizing: border-box;
    align-items: flex-start;

    @media screen and (max-width: 500px) {
      width: 100%;
      background-color: white;
      max-height: unset;
      height: 100vh;
    }

    div.fixed {
      position: absolute;
      top: 20px;
      left: 20px;
      display: flex;
      font-size: 25px;

      .icons {
        color: #aaaaaa;
        cursor: pointer;

        &:not(:last-of-type) {
          margin-right: 10px;
        }

        &:hover {
          color: black;
        }
      }
    }

    div.grid {
      display: grid;
      grid-template-columns: repeat(4, 70px);
      gap: 10px;

      div.elem {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70px;
        height: 70px;
        font-size: 50px;
        color: #aaaaaa;
        border: 1px solid #aaaaaa;
        transition: border-color .2s ease, color .2s ease;
        
        &:not(.no-hover) {
          cursor: pointer;
        }

        &:not(.no-hover):hover, &.active {
          color: black;
          border-color: black;
        }
      }
    }

    div.scrollable {
      margin-top: 35px;
      width: 100%;
      max-height: 100%;
      overflow: auto;

      /* width */
      &::-webkit-scrollbar {
        width: 6px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #aaaaaa;
        border-radius: 3px;
        transition: opacity .2s ease, color .2s ease;
        opacity: 0;
      }

      &:hover::-webkit-scrollbar-thumb {
        opacity: 1;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: white;
      }
    }

    div.info {
      display: flex;

      div.side {
        font-size: 25px;
        color: black;
        width: 45px;
        display: flex;
        justify-content: center;
      }

      div.content {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        max-width: calc(100% - 45px);

        span,
        p,
        a {
          font-family: $be-vietnam;
          text-align: right;
          color: black;
        }

        span.title {
          font-size: 18px;
          font-weight: 700;
        }

        span.subtitle {
          font-size: 16px;
          font-weight: 100;
        }

        p {
          font-size: 14px;
          font-weight: 100;
          margin: 15px 0;
        }

        .other {
          font-size: 15px;
          text-decoration: none;
        }
      }
    }

    div.separator {
      width: 100%;
      height: 1px;
      background: #aaaaaa;
      margin: 30px 0;
    }

    div.total {
      display: flex;

      div.side {
        font-size: 21px;
        color: black;
        width: 45px;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        padding-top: 4px;
      }

      div.content {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        max-width: calc(100% - 45px);

        span.recap {
          font-family: $be-vietnam;
          text-align: right;
          font-size: 18px;
          font-weight: 700;
          color: black;
          margin-bottom: 15px;

          span.thin {
            font-weight: 100;
          }
        }
      }
    }
  }
}
.list-wrapper {
    width: 90%;
    max-width: 400px;
    margin: 30px auto;
}

/* List component */
.list {
    margin: 0;
    padding: 8px;
    .list-item{
        display: flex;
        padding: 4px 5px;
    }
    .list-item:not(:last-child){
        border-bottom: 1px solid #EEE;
    }
    .list-item__image{
        flex-shrink: 0;
    }
    .list-item__image img{
        width: 50px;
        height: 50px;
    }
    .list-item__content{
        flex-grow: 1;
        padding: 0 20px;
    }
    .list-item__content h4{
        margin: 0;
        font-size: 18px;
        margin-top: 15px;
    }
    .list-item__content p{
        margin-top: 5px;
        color: #AAA;
        margin-bottom: 0;
    }
}

</style>